import { useColorModeValue } from "@chakra-ui/react";
import type { UserRead, ValidationError } from "typescript-axios";
import { navigate } from "vike/client/router";
import merge from "lodash/merge";
import imageCompression from 'browser-image-compression'
import { usePageContext } from "vike-react/usePageContext";
import translate from "src/locales/translate";

export function dataUriToFile(dataURI: string, nameTransform: ((name: string) => string) | undefined = undefined): File {
  // Find the base64 part and the filename in the data URI
  const matches = dataURI.match(/^data:(.*);name=(.*);base64,(.*)$/);
  if (!matches) {
    throw new Error("Invalid data URI");
  }

  const mimeType = matches[1];
  const fileName = matches[2];
  const base64String = matches[3];

  // Decode the base64 string
  const byteString = atob(base64String);

  // Write the bytes of the string to a typed array
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i += 1) {
    int8Array[i] = byteString.charCodeAt(i);
  }

  // Create a blob from the typed array
  const blob = new Blob([arrayBuffer], { type: mimeType });

  // Create a file from the blob
  return new File([blob], nameTransform ? nameTransform(fileName) : fileName, { type: mimeType });
}

export const logout = (setUser: (user: UserRead | null) => void) =>
  fetch("/api/logout/").then(() => setUser(null));

type ApiResponseError = {
  response?: {
    data?: {
      detail?: string | ValidationError;
    };
  };
};

export const blobUrlToFile = (blobUrl, filename) => {
  return fetch(blobUrl)
    .then(response => response.blob()) // Convert the response to a Blob
    .then(blob => {
      const fileExtension = blob.type.split(";")[0].split("/")[1];
      return new File([blob], `${filename}.${fileExtension}`, { type: blob.type })
    });
}

export const getReadableApiError = (
  e: TypeError | ApiResponseError | { message: string; detail?: string }
): string => {
  // Handle ApiResponseError structure
  if ("response" in e && e.response?.data?.detail) {
    const { detail } = e.response.data;
    if (typeof detail === "string") {
      return detail;
    }
    if (typeof detail === "object" && "msg" in detail) {
      return detail.msg;
    }
  }

  // Handle error with message property
  if ("message" in e) {
    return e.message;
  }

  // Handle error with detail property directly on e
  if ("detail" in e && typeof e.detail === "string") {
    return e.detail;
  }

  return "Something went wrong";
};

export const redirectIfNotAuthorized = (e) => {
  if (e.response.status === 401 || e.response.status === 403) {
    // avoid infinite loop
    if (typeof window === 'undefined') return;
    const next =
      window.location.pathname === "/auth/logout"
        ? "/"
        : window.location.pathname;

    const urlEncodedNext = encodeURIComponent(next);
    // can't use navigate() as it does not preserve encoding.
    // maybe follow: https://github.com/vikejs/vike/pull/2240
    window.location.href = `/auth/logout?next=${urlEncodedNext}`
  } else {
    throw e;
  }
};


export const rndColor = (id: string) => {
  // randomness should come from template id
  const intVal = [50, 100, 200, 300];
  // turn id (str) to int
  const idInt = id.split("").reduce((acc, c) => acc + c.charCodeAt(0), 0);
  const rand = intVal[idInt % intVal.length];
  const randStyle = ["brandSecondary", "brandTernary", "brandQuaternary"][
    idInt % 3
  ];
  return useColorModeValue(`${randStyle}.${rand}`, `${randStyle}.${rand}`);
};

export const deleteSearchParam = (param: string) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);
  navigate(url.toString());
};

export const deleteSearchParams = (params: string[]) => {
  const url = new URL(window.location.href);
  for (const param of params) {
    url.searchParams.delete(param);
  }
  navigate(url.toString());
};

export const setSearchParams = (params) => {
  const url = new URL(window.location.href);
  for (const [key, value] of Object.entries(params)) {
    url.searchParams.set(key, value);
  }
  navigate(url.toString());
}

export function findFileProperties(schema, path = '', result = []) {
  // Check if the current schema has the "is-file" flag
  if (schema['is-file'] === true) {
    result.push(path); // Save the path of this property
  }

  // Recursively go through properties if this schema has them
  if (schema.properties) {
    for (const key in schema.properties) {
      findFileProperties(schema.properties[key], `${path}.${key}`, result);
    }
  }

  // Recursively go through items if this schema has them (for arrays)
  if (schema.items) {
    findFileProperties(schema.items, `${path}.items`, result);
  }

  return result;
}

export function getNestedValue(obj, path, pop = false) {
  const keys = path.startsWith('.') ? path.slice(1).split('.') : path.split('.');
  let result = obj;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (result && key in result) {
      // Check if we're at the last key
      if (i === keys.length - 1) {
        const value = result[key];
        if (pop) {
          delete result[key]; // Remove the key if pop is true
        }
        return value;
      } else {
        result = result[key];
      }
    } else {
      return undefined; // Return undefined if the path does not exist
    }
  }
}

const getUiSchema = (schema, replaceExample = false) => {
  if (!replaceExample) {
    return schema.uiSchema || {};
  }
  if (schema.uiExampleSchema) {
    return schema.uiExampleSchema;
  }
  return schema.uiSchema || {};
}

export function applyi18nToSchema(schema, translate) {
  // recursively apply i18n to schema
  function applyi18nToProperties(properties) {
    for (const key in properties) {
      const property = properties[key];
      if (property.type === 'object' && property.properties) {
        applyi18nToProperties(property.properties);
      } else {
        if (property.title) {
          property.title = translate(property.title);
        }
        if (property.description) {
          property.description = translate(property.description);
        }
      }
    }
  }

  applyi18nToProperties(schema.properties);
  return schema;

}

export function generateUiSchema(schema, toMerge = {}, replaceExample = false) {
  const uiSchema = { ...getUiSchema(schema, replaceExample) };
  function buildUiSchema(properties, parentUiSchema) {
    for (const key in properties) {
      const property = properties[key];
      parentUiSchema[key] = {}; // Initialize uiSchema for each property

      // FIXME: this is disgusting but i have no time
      if (property.file_label_override && property.properties?.file) {
        property.properties.file.title = property.file_label_override;
      }

      // Customize uiSchema based on property attributes
      if (property.type === 'object' && property.properties) {
        // Recursively handle nested objects
        buildUiSchema(property.properties, parentUiSchema[key]);
        parentUiSchema[key] = { ...getUiSchema(property, replaceExample), ...parentUiSchema[key] };
      } else {
        // Set basic widgets based on types or any other property customization
        parentUiSchema[key] = getUiSchema(property, replaceExample)
      }

    }
  }

  // Start building uiSchema
  buildUiSchema(schema.properties, uiSchema);

  // Merge with custom uiSchema
  return merge(uiSchema, toMerge);
}

export async function compressImage(imageFile, maxSizeMB = 2) {
  const options = {
    maxSizeMB: maxSizeMB,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }
  try {
    // this shit requires https://caniuse.com/?search=globalThis
    // the type is a hack as it is not preserved by imageCompression if it contains other metadata
    // also see https://github.com/Donaldcwl/browser-image-compression/pull/203
    const fileType = imageFile.type.split(";")[0]
    const blobFile = await imageCompression(imageFile, { ...options, fileType })
    return new File([blobFile], imageFile.name, { type: fileType.type });
  } catch (error) {
    console.error(error)
    return imageFile
  }
}

export const useTranslation = () => {
  const { locale } = usePageContext()
  const t = (text, args = {}) => translate(text, locale, args)
  return t
}
